import { TenantDashboardDataset } from './tenant-dashboard-dataset';

export class TenantDashboardChartData {
    datasets: TenantDashboardDataset[];
    labels: string[];
    isDatasetEmpty: boolean;

    constructor(
        data_series: {
            name: string;
            values: number[];
            color: string;
            hoverBackgroundColor: string;
            hoverBorderColor: string;
        }[],
        labels: string[]
    ) {
        this.datasets = data_series.map((data) => new TenantDashboardDataset(data.name, data.values, data.color, data.hoverBackgroundColor, data.hoverBorderColor));
        this.labels = labels;
    }
}
