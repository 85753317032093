import { HttpClient, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { IUserQuery } from 'app/projects/user/src/lib/interfaces/user.query.interface';
import { User } from 'app/projects/user/src/lib/models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IChoosenFilterInterface } from '../interfaces/choosen-filter.interface';
import { ExtendedAttributeFilterInterface } from '../interfaces/extended-attribute-filer.interface';

export interface IUsersAndTotalCount {
    data: IUserQuery[];
    pagination: {
        number_of_pages: number;
        number_of_results: number;
    };
}
export interface IRootAudienceUsersAndTotalCount {
    data: { sub_audience: []; user: IUserQuery }[];
    pagination: {
        number_of_pages: number;
        number_of_results: number;
    };
}

/**
 * I had to use a custom encoding
 * because we use the bracket in the url for the FILTER user
 */
export class MyCustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
    encodeKey(k: string): string {
        return super.encodeKey(k).replace(new RegExp('%5B', 'g'), '[').replace(new RegExp('%5D', 'g'), ']');
    }
}

@Injectable()
export class AllUsersApiService {
    private _filtersList = new BehaviorSubject<IChoosenFilterInterface[]>([]);
    public filtersList$ = this._filtersList.asObservable();

    constructor(private http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment) {}

    setFiltersFromStorage(key: string): void {
        const filters: IChoosenFilterInterface[] = JSON.parse(sessionStorage.getItem(key));

        if (filters?.length) {
            this._filtersList.next(filters);
        }
    }

    getFiltersFromStorage(key: string): IChoosenFilterInterface[] {
        const filters: IChoosenFilterInterface[] = JSON.parse(sessionStorage.getItem(key));

        return filters;
    }

    setFiltersList(key: string, value: IChoosenFilterInterface[], toEditSessionStorage: boolean = false): void {
        this._filtersList.next(value);

        if (toEditSessionStorage) {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    findUsers(
        moduleIds: string[] = [],
        entityId = '',
        search = '',
        sortBy = '',
        sortOrder = '',
        pageSize = 10,
        pageIndex = 1,
        isUnassignedSelected = false,
        role = '',
        includeAnalytics = false,
        experienceIds: string[] = [],
        audienceIds: string[] = [],
        teamIds: string[] = [],
        extendedAttributesFilters: ExtendedAttributeFilterInterface[] = [],
        isUnassignedSelectedExperiences = false,
        isUnassignedSelectedAudiences = false,
        isUnassignedSelectedTeams = false,
        columns: string[] = [],
        userRoleIds: string[] = []
    ): Observable<IUsersAndTotalCount> {
        let params: HttpParams = new HttpParams({
            encoder: new MyCustomHttpUrlEncodingCodec(),
        });

        params = params.set('page', pageIndex.toString()).set('limit', pageSize.toString());

        if (extendedAttributesFilters.length) {
            let attributeValues = {};

            extendedAttributesFilters.forEach((attr) => {
                if (attr.value) {
                    if (!attributeValues[attr.attributeName]) {
                        attributeValues[attr.attributeName] = [];
                    }

                    attributeValues[attr.attributeName].push(attr.value);
                } else {
                    params = params.set(`filter[${attr.attributeName}][gte]`, attr.rangeValue1);
                    params = params.set(`filter[${attr.attributeName}][lte]`, attr.rangeValue2);
                }
            });

            Object.keys(attributeValues).forEach((key) => {
                params = params.append(`filter[${key}]`, attributeValues[key].join(', '));
            });
        }

        if (entityId) {
            params = params.set('entityId', entityId);
        }

        if (includeAnalytics) {
            params = params.set('includeAnalytics', 'true');
        }

        if (search) {
            params = params.set('search', search);
        }

        if (moduleIds.length) {
            params = params.append('filter[module]', moduleIds.map((moduleId) => moduleId).toString());
        }

        if (experienceIds.length) {
            params = params.append('filter[experience]', experienceIds.map((experienceId) => experienceId).toString());
        }

        if (audienceIds.length) {
            params = params.append('filter[audience]', audienceIds.map((audienceId) => audienceId).toString());
        }

        if (teamIds.length) {
            params = params.append('filter[team]', teamIds.map((teamId) => teamId).toString());
        }

        if (userRoleIds.length) {
            params = params.append('filter[userRole]', userRoleIds.map((userRoleId) => userRoleId).toString());
        }

        if (sortBy && sortOrder) {
            switch (sortBy) {
                case 'fullName':
                    sortBy = 'firstName';
                    break;
            }

            params = params.set('sortBy', sortBy);
            params = params.set('sortOrder', sortOrder);
        }

        if (isUnassignedSelected) {
            params = params.set('filter[module]', 'null');
        }

        if (isUnassignedSelectedExperiences) {
            params = params.set('filter[experience]', 'null');
        }

        if (isUnassignedSelectedAudiences) {
            params = params.set('filter[audience]', 'null');
        }

        if (isUnassignedSelectedTeams) {
            params = params.set('filter[team]', 'null');
        }

        if (role) {
            params = params.set('role', role);
        }

        if (columns.length) {
            params = params.set('filter[columns]', columns.map((column) => column).toString());
        }

        return this.http.get<IUsersAndTotalCount>(`${this.environment.apiUrl}/users`, { params });
    }

    getAll(): Observable<User[]> {
        return this.http.get<IUserQuery[]>(`${this.environment.apiUrl}/users`).pipe(map((queries) => queries.map((query) => new User(query))));
    }

    getAvailableUsers(groupId = '', search = '', sortBy = '', sortOrder = '', pageSize = 5, pageIndex = 1): Observable<IUsersAndTotalCount> {
        let params = new HttpParams().set('page', pageIndex.toString()).set('limit', pageSize.toString());

        if (groupId) {
            params = params.set('groupId', groupId);
        }

        if (search) {
            params = params.set('search', search);
        }

        if (sortBy && sortOrder) {
            switch (sortBy) {
                case 'fullName':
                    sortBy = 'firstName';
                    break;
            }

            params = params.set('sortBy', sortBy);
            params = params.set('sortOrder', sortOrder);
        }

        return this.http.get<IUsersAndTotalCount>(`${this.environment.apiUrl}/users/participant`, { params });
    }

    getParticipantsAndResponsibles(moduleId: string, groupId = '', search = '', sortBy = '', sortOrder = '', pageSize = 10, pageIndex = 1): Observable<IUsersAndTotalCount> {
        let params = new HttpParams().set('page', pageIndex.toString()).set('limit', pageSize.toString());

        if (groupId) {
            params = params.set('groupId', groupId);
        }

        if (search) {
            params = params.set('search', search);
        }

        if (sortBy && sortOrder) {
            switch (sortBy) {
                case 'fullName':
                    sortBy = 'firstName';
                    break;
            }

            params = params.set('sortBy', sortBy);
            params = params.set('sortOrder', sortOrder);
        }

        return this.http.get<IUsersAndTotalCount>(`${this.environment.apiUrl}/modules/${moduleId}/participants-and-responsibles`, { params });
    }

    getRootAudienceUsers(audienceId: string, search = '', sortBy = '', sortOrder = '', pageSize = 10, pageIndex = 1): Observable<IRootAudienceUsersAndTotalCount> {
        let params = new HttpParams().set('page', pageIndex.toString()).set('limit', pageSize.toString());

        if (search) {
            params = params.set('search', search);
        }

        if (sortBy && sortOrder) {
            switch (sortBy) {
                case 'fullName':
                    sortBy = 'firstName';
                    break;
            }

            params = params.set('sortBy', sortBy);
            params = params.set('sortOrder', sortOrder);
        }

        return this.http.get<IRootAudienceUsersAndTotalCount>(`${this.environment.apiUrl}/engage/audiences/${audienceId}/users`, { params });
    }

    async fetchResponsibleParticipantByEmail(moduleId: string, search: string): Promise<IUserQuery[]> {
        const params = new HttpParams().set('search', search);
        return this.http.get<IUserQuery[]>(`${this.environment.apiUrl}/modules/${moduleId}/participants-and-responsibles`, { params }).toPromise();
    }

    getCourseOfferingUsers(courseOfferingId = '', search = '', sortBy = '', sortOrder = '', pageSize = 10, pageIndex = 1): Observable<IUsersAndTotalCount> {
        let params = new HttpParams().set('page', pageIndex ? pageIndex.toString() : '1').set('limit', pageSize ? pageSize.toString() : '10');

        if (search) {
            params = params.set('filter[query]', search);
        }

        if (sortBy && sortOrder) {
            if (sortBy === 'name' || sortBy === 'fullName') {
                sortBy = 'name';
            }

            params = params.set('sortBy', `${sortBy}`);
            params = params.set('sortOrder', sortOrder);
        }

        return this.http.get<IUsersAndTotalCount>(`${this.environment.apiUrl}/course-structure/course-offerings/${courseOfferingId}/users`, {
            params,
        });
    }

    getCommunityUsers(
        entityId = '',
        search = '',
        sortBy = '',
        sortOrder = '',
        pageSize = 10,
        pageIndex = 1,
        extendedAttributesFilters: ExtendedAttributeFilterInterface[] = [],
        columns: string[] = []
    ): Observable<IUsersAndTotalCount> {
        let params: HttpParams = new HttpParams({
            encoder: new MyCustomHttpUrlEncodingCodec(),
        });

        params = params.set('page', pageIndex.toString()).set('limit', pageSize.toString());

        if (extendedAttributesFilters.length) {
            let attributeValues = {};

            extendedAttributesFilters.forEach((attr) => {
                if (attr.value) {
                    if (!attributeValues[attr.attributeName]) {
                        attributeValues[attr.attributeName] = [];
                    }

                    attributeValues[attr.attributeName].push(attr.value);
                } else {
                    params = params.set(`filter[${attr.attributeName}][gte]`, attr.rangeValue1);
                    params = params.set(`filter[${attr.attributeName}][lte]`, attr.rangeValue2);
                }
            });

            Object.keys(attributeValues).forEach((key) => {
                params = params.append(`filter[${key}]`, attributeValues[key].join(', '));
            });
        }

        if (search) {
            params = params.set('search', search);
        }

        if (sortBy && sortOrder) {
            switch (sortBy) {
                case 'fullName':
                    sortBy = 'firstName';
                    break;
            }

            params = params.set('sortBy', sortBy);
            params = params.set('sortOrder', sortOrder);
        }

        if (columns.length) {
            params = params.set('filter[columns]', columns.map((column) => column).toString());
        }

        return this.http.get<IUsersAndTotalCount>(`${this.environment.apiUrl}/engage/home-screen/community-widget/${entityId}/users`, { params });
    }
}
