import { IMobilePostMessage } from 'app/projects/shared/src/lib/interfaces/mobile-post-message.interface';

export class MobilePostMessage {
    id: string;
    type: number;
    payload: any = {};

    constructor(query: IMobilePostMessage) {
        this.id = query.id;
        this.type = query.type;
        this.payload = query.payload;
    }
}
