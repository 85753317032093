import { PerformancePeriod } from 'app/library/performance-period/models/performance-period.model';
import { TenantDashboard } from 'app/library/tenant-dashboard/models/tenant-dashboard';
import { SimpleEntity } from 'app/projects/shared/src/lib/models/simple-entity.model';
import { GlobalParticipant } from 'app/projects/user/src/lib/models/participant.model';
import * as moment from 'moment';
import { IPerformanceTeamReviewQuery } from '../interfaces/performance-team-review.query.interface';

// @dynamic
export class PerformanceTeamReview {
    id: string;
    name: string;
    type?: SimpleEntity;
    status?: string;
    startDate?: string;
    dueDate: string;
    periodUnderReview?: PerformancePeriod;
    goalSettingPeriod?: {
        id: string;
        startDate: string;
        endDate: string;
        overarchingGoalCount: number;
    };
    teams?: SimpleEntity[];
    numberOfPersonalReviews?: number;
    isOverdue?: boolean;
    isStartDateInFuture?: boolean;
    charts?: TenantDashboard[];
    creator?: string;
    createdBy?: GlobalParticipant;
    templateVersion?: {
        id: string;
        template: SimpleEntity;
    };

    team?: {
        id: string;
    };
    userIds?: string[];

    constructor(query: IPerformanceTeamReviewQuery) {
        this.id = query.id.toString();
        this.name = query.name;
        this.startDate = query.start_date;
        this.dueDate = query.due_date;
        this.type = query.type ? new SimpleEntity(query.type) : null;
        if (query.period_under_review) {
            this.periodUnderReview = new PerformancePeriod(query.period_under_review);
        }
        if (query.goal_setting_period) {
            this.goalSettingPeriod = {
                id: query.goal_setting_period.id.toString(),
                startDate: query.goal_setting_period.start_date,
                endDate: query.goal_setting_period.end_date,
                overarchingGoalCount: query.goal_setting_period.overarching_goal_count,
            };
        }
        this.teams = query.teams ? query.teams.map((team) => new SimpleEntity(team)) : [];
        this.numberOfPersonalReviews = query.number_of_personal_reviews;
        this.status = query.status;
        this.isOverdue = query.is_overdue;
        this.isStartDateInFuture = query.start_date ? moment(query.start_date).isAfter(moment()) : null;
        this.charts = query.charts ? query.charts.map((chart) => new TenantDashboard(chart)) : [];
        this.creator = query.creator;
        this.createdBy = query.created_by ? new GlobalParticipant(query.created_by) : null;
        this.team = {
            id: query.team ? query.team.id.toString() : null,
        };

        if (query.template_version) {
            this.templateVersion = { id: query.template_version.id.toString(), template: new SimpleEntity(query.template_version.template) };
        }
    }
}
