import { ExtendedAttribute } from 'app/library/extended-attributes/models/extended-attribute';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserExtendedAttributeValue } from 'app/projects/user/src/lib/models/user-attribute-value';
import { ITrainingApprovalRequestQuery } from '../interfaces/training-approval-request.query.interface';
import { TrainingApprovalRequestActor } from './training-approval-request-actor.model';

export class TrainingApprovalRequest {
    static props_to_search = ['name'];

    id: string;
    courseName: string;
    courseProvider: string;
    datesEnabled: true;
    startDate: string;
    endDate: string;
    team: {
        id: string;
        name: string;
    };
    status: string;
    requestDate: string;
    user: User;
    attributes: { attribute: ExtendedAttribute; value: UserExtendedAttributeValue }[];
    requestAttributes: { attribute: ExtendedAttribute; value: UserExtendedAttributeValue }[] = [];
    approvalAttributes: { attribute: ExtendedAttribute; value: UserExtendedAttributeValue }[] = [];
    assignee: TrainingApprovalRequestActor;
    requestHelp: {};
    updatedAt: string;

    constructor(query?: ITrainingApprovalRequestQuery) {
        if (query) {
            this.id = query.id.toString() || null;
            this.courseName = query.course_name || '';
            this.courseProvider = query.course_provider || '';
            this.datesEnabled = query.dates_enabled;
            this.startDate = query.start_date || '';
            this.endDate = query.end_date || '';
            if (query.team) {
                this.team = {
                    id: query.team.id.toString(),
                    name: query.team.name,
                };
            }
            this.status = query.status || '';
            this.requestDate = query.request_date || '';
            this.user = query.user ? new User(query.user) : null;
            this.attributes = query.attributes.map((item) => {
                return {
                    attribute: new ExtendedAttribute(item.attribute),
                    value: new UserExtendedAttributeValue(item.value),
                };
            });
            this.assignee = query.assignee ? new TrainingApprovalRequestActor(query.assignee) : null;
            this.requestHelp = query.request_help || {};
            this.updatedAt = query.updated_at || '';
        } else {
            this.id = null;
            this.courseName = null;
            this.courseProvider = null;
            this.datesEnabled = null;
            this.startDate = null;
            this.endDate = null;
            this.team = null;
            this.status = null;
            this.requestDate = null;
            this.user = null;
            this.attributes = null;
            this.assignee = null;
            this.requestHelp = null;
            this.updatedAt = null;
        }
    }
}
