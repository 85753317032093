import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { FuseConfigService } from 'app/projects/fuse/src/lib/services/config.service';
import { MobilePostMessageType } from 'app/projects/shared/src/lib/enums/mobile-message-type.enum';
import { IMobilePostMessage } from 'app/projects/shared/src/lib/interfaces/mobile-post-message.interface';
import { MobilePostMessage } from 'app/projects/shared/src/lib/models/mobile-post-message.model';
import { MobileAppService } from 'app/services/mobile-app.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-kmp-landing-page',
    templateUrl: './kmp-landing-page.component.html',
    styleUrls: ['./kmp-landing-page.component.scss'],
})
export class KmpLandingPageComponent {
    constructor(private _fuseConfigService: FuseConfigService, private _mobileAppService: MobileAppService, private _store: Store) {
        this._fuseConfigService.config = {
            layout: {
                toolbar: {
                    hidden: true,
                },
                navbar: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        localStorage.removeItem('expiresAt');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        this._mobileAppService.sendPostMessage(new MobilePostMessage({ id: uuidv4(), type: MobilePostMessageType.AuthPending, payload: {} }));

        window.onMobileMessage = (query: string) => {
            const data: IMobilePostMessage = JSON.parse(query);

            if (data.type === MobilePostMessageType.Auth) {
                localStorage.setItem('refreshToken', data.payload.refresh_token);
                this._store.dispatch(new Navigate(['/mobile-loading-page']));
            }
        };
    }
}
