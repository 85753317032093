import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UploadFileCommand } from 'app/library/content-node/models/upload-file.command';
import { ISearchQuery } from 'app/library/module-content-nodes/interfaces/search.query.interface';
import { SetModuleKeyValue } from 'app/library/module/actions/set-module-key-value.action';
import { AddSections } from 'app/library/section/actions/add-entities.action';
import { SetSectionContentNodeId } from 'app/library/section/actions/set-section-content-node.action';
import { ISectionQuery } from 'app/library/section/interfaces/section.query.interface';
import { FeedbackSection } from 'app/library/section/models/feedback-section.model';
import { FilesSection } from 'app/library/section/models/files-section.model';
import { QuizSection } from 'app/library/section/models/quiz-section.model';
import { Section } from 'app/library/section/models/section.model';
import { SurveySection } from 'app/library/section/models/survey-section.model';
import { TextSection } from 'app/library/section/models/text-section.model';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { FileCommand } from 'app/projects/core/src/lib/models/file.command';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SectionType } from '../../section/enums/section-type.enum';
import { ELearningSection } from '../../section/models/eLearning-section.model';
import { IBulkUpdateCopyProtectionQuery } from '../interfaces/bulk-update-protection.query.interface';
import { IContentNodeChildren } from '../interfaces/content-node-children.interface';
import { IContentNodeChildrenQuery } from '../interfaces/content-node-children.query.interface';
import { IContentNodeQuery } from '../interfaces/content-node.query.interface';
import { ICreateFileQuery } from '../interfaces/create-file.interface';
import { ICreateFolderActionPayload } from '../interfaces/create-folder.action-payload.interface';
import { IPublishContentNodesActionPayload } from '../interfaces/publish-content-nodes.action-payload.interface';
import { ISectionContentNodeChildrenQuery } from '../interfaces/section-content-node-children.query.interface';
import { IUpdateFileActionPayload } from '../interfaces/update-file.action-payload.interface';
import { BulkUpdateCopyProtectionCommand } from '../models/bulk-update-copy-protection.command';
import { ContentNode } from '../models/content-node.model';
import { UploadZipCommand } from '../models/upload-zip.command';
import { ContentNodeService } from './content-node.service';
import { IntegratedQuizSection } from 'app/library/section/models/integrated-quiz-section.model';

@Injectable()
export class ContentNodeApiService {
    private apiUrl: string;

    constructor(private http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) { apiUrl }: IEnvironment, private _store: Store, private _contentNodeService: ContentNodeService) {
        this.apiUrl = apiUrl;
    }

    private _newSection(query: ISectionQuery): Section {
        switch (query.type) {
            case SectionType.Files:
                return new FilesSection(query);
            case SectionType.Text:
                return new TextSection(query);
            case SectionType.Feedback:
                return new FeedbackSection(query);
            case SectionType.Quiz:
                return new QuizSection(query);
            case SectionType.IntegratedQuiz:
                return new IntegratedQuizSection(query);
            case SectionType.ELearning:
                return new ELearningSection(query);
            case SectionType.Survey:
                return new SurveySection(query);
        }
    }

    module(moduleId: string): Observable<IContentNodeChildren> {
        return this.http.get<IContentNodeChildrenQuery>(`${this.apiUrl}/modules/${moduleId}/nodes/admin-root`).pipe(
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            })),
            tap((response) => this._store.dispatch(new SetModuleKeyValue({ moduleId, key: 'contentNodeId', value: response.parent.id })))
        );
    }

    private(moduleId: string): Observable<IContentNodeChildren> {
        return this.http.get<IContentNodeChildrenQuery>(`${this.apiUrl}/modules/${moduleId}/nodes/private-root`).pipe(
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            })),
            tap((response) => this._store.dispatch(new SetModuleKeyValue({ moduleId, key: 'userContentNodeId', value: response.parent.id })))
        );
    }

    moduleSections(moduleId: string): Observable<IContentNodeChildren> {
        return this.http.get<ISectionContentNodeChildrenQuery>(`${this.apiUrl}/modules/${moduleId}/sections/nodes/admin-root`).pipe(
            tap((response) =>
                this._store.dispatch(
                    new AddSections(
                        response.nodes.map((node) => {
                            const section = this._newSection(node.metadata.section);

                            setTimeout(() => {
                                this._store.dispatch(new SetSectionContentNodeId({ sectionId: section.id, contentNodeId: node.metadata.node }));
                            });

                            return section;
                        })
                    )
                )
            ),
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            }))
        );
    }

    getSharableLinkNode(root_node_id: string, relative_path: string): Promise<ContentNode> {
        return this.http
            .post<IContentNodeQuery>(`${this.apiUrl}/node/sharable-link`, {
                root_node_id,
                relative_path,
            })
            .pipe(map((response) => new ContentNode(response)))
            .toPromise();
    }

    privateSections(moduleId: string): Observable<IContentNodeChildren> {
        return this.http.get<ISectionContentNodeChildrenQuery>(`${this.apiUrl}/modules/${moduleId}/sections/nodes/private-root`).pipe(
            tap((response) =>
                this._store.dispatch(
                    new AddSections(
                        response.nodes.map((node) => {
                            const section = this._newSection(node.metadata.section);

                            section.userContentNodeId = node.metadata.node;

                            return section;
                        })
                    )
                )
            ),
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            }))
        );
    }

    shared(moduleId: string): Observable<IContentNodeChildren> {
        return this.http.get<IContentNodeChildrenQuery>(`${this.apiUrl}/modules/${moduleId}/nodes/shared-root`).pipe(
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            })),
            tap((response) => this._store.dispatch(new SetModuleKeyValue({ moduleId, key: 'sharedContentNodeId', value: response.parent.id })))
        );
    }

    moduleGroups(groupId: string): Observable<IContentNodeChildren> {
        return this.http.get<IContentNodeChildrenQuery>(`${this.apiUrl}/modules/groups/${groupId}/nodes/root`).pipe(
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            }))
        );
    }

    groups(groupId: string): Observable<IContentNodeChildren> {
        return this.http.get<IContentNodeChildrenQuery>(`${this.apiUrl}/general/groups/${groupId}/nodes/root`).pipe(
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            }))
        );
    }

    getPersonalContentNodes(moduleId: string, userId: string): Observable<IContentNodeChildren> {
        return this.http.get<IContentNodeChildrenQuery>(`${this.apiUrl}/modules/${moduleId}/users/${userId}/personal/nodes/root`).pipe(
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            }))
        );
    }

    getPersonalManagersContentNodes(moduleId: string): Observable<IContentNodeChildren> {
        return this.http.get<IContentNodeChildrenQuery>(`${this.apiUrl}/modules/${moduleId}/manager/nodes/root`).pipe(
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            }))
        );
    }

    metadata(contentNodeId: string): Observable<ContentNode> {
        return this.http.get<IContentNodeQuery>(`${this.apiUrl}/nodes/${contentNodeId}/metadata`).pipe(map((query) => new ContentNode(query)));
    }

    getChildren(contentNodeId: string): Observable<IContentNodeChildren> {
        return this.http.get<IContentNodeChildrenQuery>(`${this.apiUrl}/nodes/${contentNodeId}/children`).pipe(
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map(
                    (nodeQuery) =>
                        new ContentNode(
                            Object.assign({}, nodeQuery.metadata, { breadcrumbs: [...response.metadata.breadcrumbs, { id: response.metadata.node, name: response.metadata.name }] })
                        )
                ),
            }))
        );
    }

    search(contentNodeId: string, query: ISearchQuery): Observable<ContentNode[]> {
        return this.http
            .get<IContentNodeQuery[]>(`${this.apiUrl}/nodes/${contentNodeId}/search`, {
                params: <any>query,
            })
            .pipe(map((queries) => queries.map((nodeQuery) => new ContentNode(nodeQuery))));
    }

    copy(contentNodeId: string, targetNodeId: string): Observable<ContentNode> {
        const data = {
            target_node: targetNodeId,
        };

        return this.http.post<IContentNodeQuery>(`${this.apiUrl}/nodes/${contentNodeId}/copy`, data).pipe(map((query) => new ContentNode(query)));
    }

    move(contentNodeId: string, targetNodeId: string): Observable<ContentNode> {
        const data = {
            target_node: targetNodeId,
        };

        return this.http.post<IContentNodeQuery>(`${this.apiUrl}/nodes/${contentNodeId}/move`, data).pipe(map((query) => new ContentNode(query)));
    }

    publish(payload: IPublishContentNodesActionPayload): Observable<{ id: string; message: string }> {
        const data = {
            node: payload.node,
            notify: payload.notify,
            message: payload.notificationMessage,
        };

        return this.http.post<{ id: string; message: string }>(`${this.apiUrl}/nodes/publish`, data);
    }

    private _uploadFile(nodeId: string, file: File, data: FormData, url: string): Observable<ContentNode> {
        return this.http
            .request<IContentNodeQuery>(
                new HttpRequest('POST', url, data, {
                    reportProgress: true,
                })
            )
            .pipe(
                tap((e) => {
                    if (e.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round((100 * e.loaded) / e.total);

                        if (percentDone < 100) {
                            this._contentNodeService.updateNodesInProgress({
                                file: file.name,
                                node: nodeId,
                                percentDone,
                                success: null,
                            });
                        }
                    }
                }),
                filter((e) => e instanceof HttpResponse),
                map((e: HttpResponse<IContentNodeQuery>) => new ContentNode(e.body))
            );
    }

    uploadFile(nodeId: string, file: File): Observable<ContentNode> {
        const data = new UploadFileCommand(nodeId, file).formData;
        const url = `${this.apiUrl}/nodes/files`;

        return this._uploadFile(nodeId, file, data, url);
    }

    uploadPersonalFile(nodeId: string, file: File): Observable<ContentNode> {
        const data = new UploadFileCommand(nodeId, file).formData;
        const url = `${this.apiUrl}/personal/nodes`;

        return this._uploadFile(nodeId, file, data, url);
    }

    uploadZip(nodeId: string, file: File): Observable<ContentNode> {
        const data = new UploadZipCommand(file).formData;
        const url = `${this.apiUrl}/nodes/${nodeId}/zip-upload`;

        return this._uploadFile(nodeId, file, data, url);
    }

    createFolder(data: ICreateFolderActionPayload): Observable<ContentNode> {
        return this.http.post<IContentNodeQuery>(`${this.apiUrl}/nodes/folders`, { ...data, rename_on_conflict: false }).pipe(map((query) => new ContentNode(query)));
    }

    read(contentNodeId: string): Observable<Blob> {
        return this.http.get(`${this.apiUrl}/nodes/${contentNodeId}`, { responseType: 'blob' });
    }

    getPersonalContentNode(contentNodeId: string): Observable<Blob> {
        return this.http.get(`${this.apiUrl}/personal/nodes/${contentNodeId}`, { responseType: 'blob' });
    }

    download(contentNode: ContentNode): Observable<Blob> {
        const endpointUrl = `${this.apiUrl}/nodes/${contentNode.id}`;
        const mobileAppValue = localStorage.getItem('kmpMobileApp');

        if (mobileAppValue === 'ios' || mobileAppValue === 'android') {
            const accessToken = localStorage.getItem('accessToken');

            window.location.href = `${endpointUrl}?preview=true&access_token=${accessToken}&kmp_open_externally=true`;

            return of(new Blob());
        }

        return this.read(contentNode.id).pipe(tap((file) => MaxBrainUtils.downloadFile(file, contentNode.name)));
    }

    zip(contentNode: ContentNode): Observable<Blob> {
        return this.http
            .get(`${this.apiUrl}/nodes/${contentNode.id}/zip`, { responseType: 'blob' })
            .pipe(tap((file) => MaxBrainUtils.downloadFile(file, `${contentNode.name}.zip`)));
    }

    update(payload: IUpdateFileActionPayload): Observable<ContentNode> {
        const data = new FileCommand(
            'file',
            new Blob([payload.newFile || payload.buffer], { type: payload.newFile ? payload.newFile.type : 'application/pdf' }),
            payload.newFile ? payload.newFile.name : payload.contentNode.name
        ).formData;

        data.append('file_version', payload.contentNode.version);

        return this.http.post<IContentNodeQuery>(`${this.apiUrl}/nodes/${payload.contentNode.id}/files`, data).pipe(map((query: IContentNodeQuery) => new ContentNode(query)));
    }

    updatePersonal(payload: IUpdateFileActionPayload): Observable<ContentNode> {
        const data = new FileCommand(
            'file',
            new Blob([payload.newFile || payload.buffer], { type: payload.newFile ? payload.newFile.type : 'application/pdf' }),
            payload.newFile ? payload.newFile.name : payload.contentNode.name
        ).formData;

        data.append('file_version', payload.contentNode.version);

        return this.http.put<IContentNodeQuery>(`${this.apiUrl}/personal/nodes/${payload.contentNode.id}`, data).pipe(map((query: IContentNodeQuery) => new ContentNode(query)));
    }

    rename(entity: ContentNode): Observable<ContentNode> {
        const data = {
            name: entity.name,
            rename_on_conflict: false,
        };

        return this.http.patch<IContentNodeQuery>(`${this.apiUrl}/nodes/${entity.id}/rename`, data).pipe(map((query) => new ContentNode(query)));
    }

    renamePersonal(entity: ContentNode): Observable<ContentNode> {
        const data = {
            name: entity.name,
            rename_on_conflict: false,
        };

        return this.http.patch<IContentNodeQuery>(`${this.apiUrl}/personal/nodes/${entity.id}/name`, data).pipe(map((query) => new ContentNode(query)));
    }

    updateMetadata(contentNodeId: string, value: boolean): Observable<ContentNode> {
        const data = {
            allow_distribution: value,
        };

        return this.http.patch<IContentNodeQuery>(`${this.apiUrl}/nodes/${contentNodeId}/metadata`, data).pipe(map((query) => new ContentNode(query)));
    }

    bulkUpdateCopyProtection(payload: IBulkUpdateCopyProtectionQuery): Observable<ContentNode> {
        const data = new BulkUpdateCopyProtectionCommand(payload);

        return this.http.patch<IContentNodeQuery>(`${this.apiUrl}/nodes/metadata/bulk`, data).pipe(map((query) => new ContentNode(query)));
    }

    delete(id: string): Observable<any> {
        return this.http.delete(`${this.apiUrl}/nodes/${id}`);
    }

    deletePersonal(nodeId: string): Observable<any> {
        return this.http.delete(`${this.apiUrl}/personal/nodes/${nodeId}`);
    }

    getMSOfficeViewUrl(id: string): Observable<{ action_url: string; favicon_url: string }> {
        return this.http.get<{ action_url: string; favicon_url: string }>(`${this.apiUrl}/nodes/${id}/msoffice-view-url`);
    }

    getMSOfficeEditUrl(id: string): Observable<{ action_url: string; favicon_url: string }> {
        return this.http.get<{ action_url: string; favicon_url: string }>(`${this.apiUrl}/nodes/${id}/msoffice-edit-url`);
    }

    createEmptyFile(payload: ICreateFileQuery): Observable<ContentNode> {
        return this.http
            .post<IContentNodeQuery>(`${this.apiUrl}/nodes/${payload.nodeId}/create-empty-file`, {
                name: payload.name,
                type: payload.type,
            })
            .pipe(map((query) => new ContentNode(query)));
    }
}
