<app-dialog-header [title]="dialogTitle" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content fxLayout="column" fxLayoutAlign="start">
    <span class="block text-base text-darktextcolor mx-24 mt-24 mb-8">{{ description | translate }}</span>
    <textarea rows="6" class="mx-24 p-8 border border-[#A8A8A8]" [(ngModel)]="data"></textarea>
</div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>

    <app-flat-button [color]="saveButtonColor" [label]="saveButtonText || 'GENERAL.BUTTON.APPLY' | translate" (onClick)="save()" [disabled]="!data?.length"></app-flat-button>
</div>
