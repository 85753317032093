import { TeamBreadcrumb } from 'app/library/team/models/team-breadcrumb.model';
import { SimpleEntity } from 'app/projects/shared/src/lib/models/simple-entity.model';
import { User } from 'app/projects/user/src/lib/models/user';
import { IPerformancePersonalReviewQuery } from '../interfaces/performance-personal-review.query.interface';

// @dynamic
export class PerformancePersonalReview {
    id: string;
    name: string;
    user: User;
    status: string;
    teamBreadcrumbs: TeamBreadcrumb[];
    type?: SimpleEntity;

    constructor(query: IPerformancePersonalReviewQuery) {
        this.id = query.id.toString();
        this.name = query.name;
        this.user = new User(query.user);
        this.status = query.status;
        if (query.type) {
            this.type = query.type;
        }

        if (query.team_breadcrumbs) {
            this.teamBreadcrumbs = query.team_breadcrumbs.map((breadcrumb) => new TeamBreadcrumb(breadcrumb));
        }
    }
}
