import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { Message } from '../../models/message.model';
import { FUSE_ASYNC_DRAWER } from 'app/projects/fuse/src/lib/services/async-drawer.token';
import { AsyncJobApiService } from 'app/services/async-job.api-service';
import { JobStatus } from '../../enums/job-status.enum';
import { Job } from '../../models/job.model';
import { JobMessageStatus } from '../../enums/job-message-status.enum';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-job-details-dialog',
    templateUrl: './job-details-dialog.component.html',
    styleUrls: ['./job-details-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class JobDetailsDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    title: string;

    @Input()
    job: Job;

    @Input()
    jobMessages: any;

    JobStatus = JobStatus;
    succeededMessages = new MatTableDataSource<Message>([]);
    failedMessages = new MatTableDataSource<Message>([]);
    messagesPagination: any = { numberOfResults: 0 };
    displayedColumns: string[] = ['title', 'status', 'errorInfo'];
    currentPage: number = 1;

    tabs = [
        {
            label: 'ASYNC_DRAWER.RESPONSE_STATS.succeeded',
            dataSource: this.succeededMessages,
            columns: [
                { name: 'avatar', header: '', cellTemplate: 'avatarCell' },
                { name: 'name', header: 'GENERAL.PROPERTY.NAME', cellTemplate: 'nameCell', sortable: true },
            ],
            columnNames: ['avatar', 'name'], // <-- Add this computed property
            key: 'succeeded',
        },
        {
            label: 'ASYNC_DRAWER.RESPONSE_STATS.failed',
            dataSource: this.failedMessages,
            columns: [
                { name: 'title', header: '', cellTemplate: 'defaultCell' },
                { name: 'status', header: '', cellTemplate: 'defaultCell' },
                { name: 'errorInfo', header: '', cellTemplate: 'defaultCell', nested: 'errorInfo.message' },
            ],
            columnNames: ['title', 'status', 'errorInfo'], // <-- Add this computed property
            key: 'failed',
        },
    ];

    async fetchMessages(jobId: string, status: string, page: number = 1) {
        const jobMessages = await this.asyncJobService.getJobMessages(jobId, page, status);

        if (status === JobMessageStatus.Succeeded) {
            this.succeededMessages.data = jobMessages; // ✅ Correct way to update
        } else {
            this.failedMessages.data = jobMessages; // ✅ Correct way to update
        }
    }

    onPageChange(event: any, status: string) {
        const page = event.pageIndex + 1; // Page index is zero-based
        this.currentPage = page; // Store current page
        this.fetchMessages('jobId', status); // Replace 'jobId' with actual job ID
    }

    constructor(public dialogRef: MatDialogRef<JobDetailsDialogComponent>, @Inject(FUSE_ASYNC_DRAWER) public asyncJobService: AsyncJobApiService) {
        super();
    }

    ngOnInit(): void {
        this.fetchMessages(this.job.id, JobMessageStatus.Succeeded);
    }
}
