import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FuseSharedModule } from 'app/projects/fuse/src/lib/shared.module';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { AsyncJobDrawerComponent } from './async-job-drawer.component';
import { GetJobStatIconPipe } from './pipes/getJobStatIcon.pipe';
import { GetJobStatsIconColorPipe } from './pipes/getJobStatsIconColor.pipe';
import { GetJobStatTextColorPipe } from './pipes/getJobStatTextColor.pipe';
import { GetJobStatusIconPipe } from './pipes/getJobStatusIcon.pipe';
import { GetJobStatusIconColorPipe } from './pipes/getJobStatusIconColor.pipe';
import { GetJobStatusColorPipe } from './pipes/getProgressBarColor.pipe';
import { JobDetailsDialogComponent } from './dialogs/job-details-dialog/job-details-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
    imports: [
        MaxBrainSharedModule,
        FuseSharedModule,
        MatExpansionModule,
        MatListModule,
        MatProgressBarModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
    ],
    declarations: [
        AsyncJobDrawerComponent,
        GetJobStatusIconPipe,
        GetJobStatusIconColorPipe,
        GetJobStatsIconColorPipe,
        GetJobStatusColorPipe,
        GetJobStatIconPipe,
        GetJobStatTextColorPipe,
        JobDetailsDialogComponent,
    ],
    exports: [AsyncJobDrawerComponent],
})
export class AsyncJobDrawerModule {}
