export class TenantDashboardAggregate {
    labelKey: string;
    unitKey: string;
    value: string;

    constructor(label_key: string, unit_key: string, value: string) {
        this.labelKey = label_key;
        this.unitKey = unit_key;
        this.value = value;
    }
}
