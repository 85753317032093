import { User } from 'app/projects/user/src/lib/models/user';
import { TrainingApprovalRequestActorRoleEnum } from '../enums/training-approval-request-actor-role.enum';
import { TrainingApprovalRequestDecisionEnum } from '../enums/training-approval-request-decision.enum';
import { ITrainingApprovalRequestActorQuery } from '../interfaces/training-approval-request-actor.query.interface';

export class TrainingApprovalRequestActor {
    id: string;
    title: string;
    subtitle: string;
    role: TrainingApprovalRequestActorRoleEnum;
    approvalDecision: TrainingApprovalRequestDecisionEnum;
    isCurrentAssignee: boolean;
    isMe: boolean;
    user: User;

    constructor(query: ITrainingApprovalRequestActorQuery) {
        this.id = query.id;
        this.title = query.title;
        this.subtitle = query.subtitle;
        this.role = query.role;
        this.approvalDecision = query.approval_decision;
        this.isCurrentAssignee = query.is_current_assignee;
        this.isMe = query.is_me;
        this.user = new User(query.user);
    }
}
