import { ISimpleEntityQuery } from '../interfaces/simple-entity.query.interface';

// @dynamic
export class SimpleEntity {
    id: string;
    name: string;

    constructor(query: ISimpleEntityQuery) {
        this.id = query.id.toString();
        this.name = query.name;
    }
}
