export enum MobilePostMessageType {
    WebViewReady = 1,
    ModuleNavigationRequest = 2,
    PathNavigationRequest = 3,
    FileOpening = 4,
    NavigationRequestSuccess = 5,
    ProfileUpdated = 6,
    ScormOpening = 7,
    ScormClosed = 8,
    OnLifecycleChange = 9,
    AuthPending = 100,
    Auth = 101,
}
