import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from '../enums/job-status.enum';

@Pipe({ name: 'getJobStatusColor' })
export class GetJobStatusColorPipe implements PipeTransform {
    transform(jobStatus: JobStatus, jobStats: any): any {
        switch (jobStatus) {
            case JobStatus.Created:
            case JobStatus.Finished:
                return jobStats.failed > 0 ? 'failed' : 'completed';
            case JobStatus.InProgress:
                return 'in-progress';
            case JobStatus.TimedOut:
                return 'timed-out';
            case JobStatus.Canceled:
                return 'failed';
            default:
                return '';
        }
    }
}
