import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-textarea-dialog',
    templateUrl: './confirm-textarea.component.html',
    styleUrls: ['./confirm-textarea.component.scss'],
})
export class MaxBrainConfirmTextareaDialogComponent {
    @Input()
    dialogTitle: string;

    @Input()
    saveButtonColor = 'accent';

    @Input()
    data: string;

    @Input()
    saveButtonText: string;

    @Input()
    description: string;

    constructor(public dialogRef: MatDialogRef<MaxBrainConfirmTextareaDialogComponent>) {}

    save(): void {
        this.dialogRef.close({ data: this.data });
    }
}
