import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';

@Injectable()
export class LoginAppApiService {
    private _http: HttpClient;
    private _env: IEnvironment;

    /**
     *
     * @param http
     * @param environment
     */
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        this._env = environment;
        this._http = http;
    }

    /**
     *
     * @param refreshToken
     */
    fetchTokenAccessToken(refreshToken: string): Promise<any> {
        const url = `https://login.${this._env.baseUrlSecondPart}/auth/token`;
        return this._http.post<any>(url, { grant_type: 'refresh_token', refresh_token: refreshToken }).toPromise();
    }

    logout(refreshToken: string): Promise<any> {
        return this._http
            .post<any>(`https://login.${this._env.baseUrlSecondPart}/auth/logout`, {
                refresh_token: refreshToken,
            })
            .toPromise();
    }
}
