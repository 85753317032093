import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ITenantConfig {
    zoom: {
        is_jwt_app: boolean;
        api_key: string;
        max_number_of_moderators: number;
    };
    chat: {
        api_key: string;
    };
    gradebook: {
        certificates: boolean;
    };
    classtime_sections: {
        original_classtime_sections: boolean;
        integrated_classtime_sections: boolean;
        feedback_360: boolean;
        school_admin_token: string;
        school_id: string;
    };
    background_image: {
        url: string;
    };
    css_splitting: {
        url: string;
    };
    survey_integration: {
        reminder_text: string;
        subject_text: string;
        public_app: boolean;
        is_token_set: boolean;
    };
    pspdfkit_license: {
        pspdfkit: string;
    };
    languages: {
        'de-DE': boolean;
        'en-GB': boolean;
        'fr-FR': boolean;
        'it-CH': boolean;
    };
    central_image_library: {
        app_id: string;
        my_images: boolean;
        tenant_library: boolean;
        unsplash: boolean;
    };
    module_notification: {
        default_text: string;
    };
    ai_images: {
        number_of_credits: number;
    };
    welcome_tour: {
        url: string;
    };
    certificate_management: {
        users_can_manage_certificates: boolean;
    };
    [key: string]: any;
}

interface ITenantData {
    logoSrc: string;
    name: string;
    supportEmail: string;
    edManUrl: string;
    ios: string;
    android: string;
    mobileTitle: string;
    mobileLogoSrc: string;
    canCreatePrivateModule: boolean;
    tenantConfig: ITenantConfig;
    subdomain: string;
    defaultLanguage: string;
}

@Injectable()
export class TenantDataService {
    private _data = new BehaviorSubject<ITenantData>(null);
    public data$ = this._data.asObservable();

    constructor() {}

    get data(): ITenantData {
        return this._data.getValue();
    }

    get logo(): string {
        const data = this.data;

        if (data) {
            return data.logoSrc;
        }

        return null;
    }

    get tenantName(): string {
        const data = this.data;

        if (data) {
            return data.name;
        }

        return null;
    }

    get subdomain(): string {
        const data = this.data;

        if (data) {
            return data.subdomain;
        }

        return null;
    }

    get supportEmail(): string {
        const data = this.data;

        if (data) {
            return data.supportEmail;
        }

        return null;
    }

    get defaultLanguage(): string {
        const data = this.data;

        if (data) {
            return data.defaultLanguage;
        }

        return null;
    }

    get edManUrl(): string {
        const data = this.data;

        if (data) {
            return data.edManUrl;
        }

        return null;
    }

    get ios(): string {
        const data = this.data;

        if (data) {
            return data.ios;
        }

        return null;
    }

    get android(): string {
        const data = this.data;

        if (data) {
            return data.android;
        }

        return null;
    }

    get tenantConfig(): ITenantConfig {
        const data = this.data;

        if (data) {
            return data.tenantConfig;
        }

        return null;
    }

    get languages(): [string, boolean][] {
        const data = this.data;

        if (data) {
            let allLanguagesArray = Object.entries(data.tenantConfig.languages);
            const usedLanguages = allLanguagesArray.filter(([key, value]) => value);
            return usedLanguages;
        }

        return [];
    }

    get isCertificateFeatureActive(): boolean {
        if (!this.tenantConfig) {
            return false;
        }

        const gradebookConfig = this.tenantConfig['gradebook'];

        return gradebookConfig ? gradebookConfig.certificates : false;
    }

    /**
     * Add some verification is tenantConfig is loaded
     * before to check the home_screen variable
     */
    get isEngageHomeScreenFeatureActive(): boolean {
        if (this.tenantConfig) {
            return this.tenantConfig['engage'] ? this.tenantConfig['engage'].home_screen : false;
        }
        return false;
    }

    setData(
        logoSrc: string,
        name: string,
        edManUrl: string,
        supportEmail: string,
        ios: string,
        android: string,
        mobileTitle: string,
        mobileLogoSrc: string,
        canCreatePrivateModule: boolean,
        tenantConfigProp: any,
        subdomain: string,
        defaultLanguage: string
    ): void {
        const tenantConfig = !tenantConfigProp
            ? null
            : {
                  ...tenantConfigProp,
                  zoom: !tenantConfigProp.zoom
                      ? null
                      : {
                            ...tenantConfigProp.zoom,
                        },
                  chat: !tenantConfigProp.chat
                      ? null
                      : {
                            ...tenantConfigProp.chat,
                        },
                  gradebook: !tenantConfigProp.gradebook
                      ? null
                      : {
                            ...tenantConfigProp.gradebook,
                        },
              };

        this._data.next({
            logoSrc,
            name,
            supportEmail,
            edManUrl,
            ios,
            android,
            mobileTitle,
            mobileLogoSrc,
            canCreatePrivateModule,
            tenantConfig,
            subdomain,
            defaultLanguage,
        });
    }
}
