import { CertificateRecord } from 'app/library/certificate-record/models/certificate-record';
import { Module } from 'app/library/module/models/module.model';
import { PerformancePersonalReview } from 'app/library/performance-personal-review/models/performance-personal-review.model';
import { PerformanceTeamReview } from 'app/library/performance-team-review/models/performance-team-review.model';
import { SectionType } from 'app/library/section/enums/section-type.enum';
import { ISectionQuery } from 'app/library/section/interfaces/section.query.interface';
import { ELearningSection } from 'app/library/section/models/eLearning-section.model';
import { FeedbackSection } from 'app/library/section/models/feedback-section.model';
import { FilesSection } from 'app/library/section/models/files-section.model';
import { QuizSection } from 'app/library/section/models/quiz-section.model';
import { Section } from 'app/library/section/models/section.model';
import { SurveySection } from 'app/library/section/models/survey-section.model';
import { TextSection } from 'app/library/section/models/text-section.model';
import { User } from 'app/projects/user/src/lib/models/user';
import { ToDoStatusEnum } from '../enums/to-do-status.enum';
import { ToDoTypeEnum } from '../enums/to-do-type.enum';
import { IToDoQuery } from '../interfaces/to-do.query.interface';
import { IntegratedQuizSection } from 'app/library/section/models/integrated-quiz-section.model';

export class ToDo {
    id: string;
    type: ToDoTypeEnum;
    status: ToDoStatusEnum;
    title: string;
    assignee: User;
    dueDate: Date;
    completedAt: Date;
    isViewedByAssignee: boolean;
    isOverdue: boolean;
    isExpired: boolean;
    moduleToDo: {
        id: string;
        module: Module;
    };
    certificateToDo: {
        id: string;
        certificate: CertificateRecord;
    };
    sectionToDo: {
        id: string;
        section: Section;
    };
    personalReviewToDo: {
        id: string;
        personalReview: PerformancePersonalReview;
    };
    teamReviewToDo: {
        id: string;
        teamReview: PerformanceTeamReview;
    };
    // TODO: Uncomment when approval request is implemented on production
    // approvalRequestToDo: {
    //     id: string;
    //     approvalRequest: TrainingApprovalRequest;
    // };

    constructor(query: IToDoQuery) {
        this.id = query.id ? query.id.toString() : null;
        this.type = query.type;
        this.status = query.status;
        this.title = query.title;
        this.assignee = new User(query.assignee);
        this.dueDate = query.due_date ? new Date(query.due_date) : null;
        this.completedAt = query.completed_at ? new Date(query.completed_at) : null;
        this.isViewedByAssignee = query.is_viewed_by_assignee;
        this.isOverdue = query.is_overdue;
        this.isExpired = query.is_expired;
        this.moduleToDo = query.module_to_do ? { id: query.module_to_do.id.toString(), module: new Module(query.module_to_do.module) } : null;
        this.certificateToDo = query.certificate_to_do
            ? { id: query.certificate_to_do.id.toString(), certificate: new CertificateRecord(query.certificate_to_do.certificate) }
            : null;
        this.sectionToDo = query.section_to_do ? { id: query.section_to_do.id.toString(), section: this._newSection(query.section_to_do.section) } : null;
        this.personalReviewToDo = query.personal_review_to_do
            ? { id: query.personal_review_to_do.id.toString(), personalReview: new PerformancePersonalReview(query.personal_review_to_do.personal_review) }
            : null;
        this.teamReviewToDo = query.team_review_to_do
            ? { id: query.team_review_to_do.id.toString(), teamReview: new PerformanceTeamReview(query.team_review_to_do.team_review) }
            : null;
    }

    private _newSection(query: ISectionQuery): Section {
        switch (query.type) {
            case SectionType.Files:
                return new FilesSection(query);
            case SectionType.Text:
                return new TextSection(query);
            case SectionType.Feedback:
                return new FeedbackSection(query);
            case SectionType.Quiz:
                return new QuizSection(query);
            case SectionType.IntegratedQuiz:
                return new IntegratedQuizSection(query);
            case SectionType.ELearning:
                return new ELearningSection(query);
            case SectionType.Survey:
                return new SurveySection(query);
        }
    }
}
