import { SimpleEntity } from 'app/projects/shared/src/lib/models/simple-entity.model';
import { ITeamBreadcrumbQuery } from '../interfaces/team-breadcrumb.query.interface';

export class TeamBreadcrumb {
    id: string;
    name: string;
    isClickable: boolean;
    breadcrumbs: SimpleEntity[];

    constructor(query: ITeamBreadcrumbQuery) {
        this.id = query.id.toString();
        this.name = query.name;
        this.isClickable = query.is_clickable;

        if (query.breadcrumbs) {
            this.breadcrumbs = query.breadcrumbs.map((breadcrumb) => new SimpleEntity(breadcrumb));
        }
    }
}
