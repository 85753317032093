import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
    selector: 'app-white-button-menu',
    templateUrl: './white-button-menu.component.html',
    styleUrls: ['./white-button-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WhiteButtonMenuComponent {
    @Input() matMenu: MatMenu;

    @Input()
    label: string = '';

    @Input()
    icon: string = '';

    @Input()
    svgIcon: string = '';

    @Input()
    disabled: boolean = false;

    @Input()
    isActive: boolean = false;

    @Input()
    showThreeDots: boolean = false;

    @Input()
    isRounded: boolean = false;

    @Output()
    onClick = new EventEmitter<any>();

    constructor() {}
}
