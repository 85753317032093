import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IContentNodeChildren } from 'app/library/content-node/interfaces/content-node-children.interface';
import { IContentNodeChildrenQuery } from 'app/library/content-node/interfaces/content-node-children.query.interface';
import { ContentNode } from 'app/library/content-node/models/content-node.model';
import { NotificationPreviewRequestType } from 'app/library/module/enums/notification-preview-request-type';
import { INotificationPrevieQuery } from 'app/library/module/interfaces/notification-preview.query.interface';
import { IParticipantRegistrationMetricQuery } from 'app/library/participant-registration-metric/interfaces/participant-registration-metric.query.interface';
import { ParticipantRegistrationMetric } from 'app/library/participant-registration-metric/models/participant-registration-metric.model';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { IEntityApiService } from 'app/projects/entity/src/lib/interfaces/entity.service.interface';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { NotifyType } from 'app/projects/shared/src/lib/enums/notify-type.enum';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SetSectionContentNodeId } from '../actions/set-section-content-node.action';
import { SectionType } from '../enums/section-type.enum';
import { ICreateClasstimeFeedbackActionPayload } from '../interfaces/create-classtime-feedback.action-payload';
import { ICreateClasstimeQuizActionPayload } from '../interfaces/create-classtime-quiz.action-payload';
import { ISectionQuery } from '../interfaces/section.query.interface';
import { ELearningSection } from '../models/eLearning-section.model';
import { FeedbackSection } from '../models/feedback-section.model';
import { FilesSection } from '../models/files-section.model';
import { QuizSection } from '../models/quiz-section.model';
import { SectionCommand } from '../models/section.command';
import { Section } from '../models/section.model';
import { SurveySection } from '../models/survey-section.model';
import { TextSection } from '../models/text-section.model';
import { CreateClasstimeFeedbackCommand } from '../models/update-classtime-feedback.command';
import { CreateClasstimeQuizCommand } from '../models/update-classtime-quiz.command';
import { ISectionMetricsQuery } from '../interfaces/section-metrics.query.interface';
import { SectionMetric } from '../models/section-metrics.model';
import { IntegratedQuizSection } from '../models/integrated-quiz-section.model';

@Injectable()
export class SectionApiService extends EntityService<ISectionQuery, SectionCommand> implements IEntityApiService<Section> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment, private store: Store) {
        super(http, environment.apiUrl, 'sections');
    }

    private _newSection(query: ISectionQuery): Section {
        switch (query.type) {
            case SectionType.Files:
                return new FilesSection(query);
            case SectionType.Text:
                return new TextSection(query);
            case SectionType.Feedback:
                return new FeedbackSection(query);
            case SectionType.Quiz:
                return new QuizSection(query);
            case SectionType.IntegratedQuiz:
                return new IntegratedQuizSection(query);
            case SectionType.ELearning:
                return new ELearningSection(query);
            case SectionType.Survey:
                return new SurveySection(query);
        }
    }

    moduleContentNodes(sectionId: string): Observable<IContentNodeChildren> {
        return this.http.get<IContentNodeChildrenQuery>(`${this.apiUrl}/sections/${sectionId}/nodes/admin-root`).pipe(
            map((response) => ({
                parent: new ContentNode(response.metadata),
                children: response.nodes.map((nodeQuery) => new ContentNode(nodeQuery.metadata)),
            })),
            tap((response) => this.store.dispatch(new SetSectionContentNodeId({ sectionId, contentNodeId: response.parent.id })))
        );
    }

    getAll(): Observable<Section[]> {
        return this._getAll().pipe(map((queries) => queries.map((query) => this._newSection(query))));
    }

    create(entity: Section): Observable<Section> {
        const data = new SectionCommand(entity);

        return this._create(data).pipe(map((query) => this._newSection(query)));
    }

    read(sectionId: string): Observable<Section> {
        return this._read(sectionId).pipe(map((query) => this._newSection(query)));
    }

    update(entity: Section): Observable<Section> {
        const data = new SectionCommand(entity);

        return this._update(entity.id, data).pipe(map((query) => this._newSection(query)));
    }

    delete(sectionId: string): Observable<any> {
        return this._delete(sectionId);
    }

    deleteSection(sectionId: string, notify: NotifyType): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/${this.entities}/${sectionId}?notify=${notify}`);
    }

    unpublishSection(sectionId: string): Observable<void> {
        return this.http.post<void>(`${this.apiUrl}/sections/${sectionId}/unpublish`, {});
    }

    toggleSectionCompletion(sectionId: string): Observable<Section[]> {
        return this.http.post<ISectionQuery[]>(`${this.apiUrl}/sections/${sectionId}/mark-complete`, {}).pipe(map((queries) => queries.map((query) => this._newSection(query))));
    }

    module(moduleId: string): Observable<Section[]> {
        return this.http.get<ISectionQuery[]>(`${this.apiUrl}/modules/${moduleId}/sections`).pipe(map((queries) => queries.map((query) => this._newSection(query))));
    }

    fetchSectionById(sectionId: string): Observable<Section> {
        return this.http.get<ISectionQuery>(`${this.apiUrl}/${this.entities}/${sectionId}`).pipe(map((query) => this._newSection(query)));
    }

    createClasstimeQuiz(sectionId: string, options: ICreateClasstimeQuizActionPayload): Observable<QuizSection> {
        const data = new CreateClasstimeQuizCommand(options);
        // we don't have to set minimum percentace, but we need to remove passing percentage from query if minimumPercantage is not selected
        if (!data.minimun_percentage) {
            delete data.passing_percentage;
        }
        delete data.minimun_percentage;
        return this.http.patch<ISectionQuery>(`${this.apiUrl}/assessment-sections/quiz-sections/${sectionId}`, data).pipe(map((query) => new QuizSection(query)));
    }

    updateClasstimeQuiz(sectionId: string, options: ICreateClasstimeQuizActionPayload): Observable<IntegratedQuizSection> {
        const data = new CreateClasstimeQuizCommand(options);
        // we don't have to set minimum percentace, but we need to remove passing percentage from query if minimumPercantage is not selected
        if (!data.minimun_percentage) {
            delete data.passing_percentage;
        }
        delete data.minimun_percentage;
        return this.http
            .patch<ISectionQuery>(`${this.apiUrl}/assessment-sections/integrated-quiz-sections/${sectionId}`, data)
            .pipe(map((query) => new IntegratedQuizSection(query)));
    }

    createClasstimeFeedback(sectionId: string, options: ICreateClasstimeFeedbackActionPayload): Observable<QuizSection> {
        const data = new CreateClasstimeFeedbackCommand(options);
        return this.http.patch<ISectionQuery>(`${this.apiUrl}/assessment-sections/feedback-360-sections/${sectionId}`, data).pipe(map((query) => new FeedbackSection(query)));
    }

    getSectionLaunchCourseUrl(sectionId: string): Observable<{ launchLink: string }> {
        return this.http.get<any>(`${this.apiUrl}/e-learning/sections/${sectionId}/launch-link`);
    }

    getSectionParticipantRegistrationData(sectionId: string): Observable<ParticipantRegistrationMetric> {
        return this.http
            .get<IParticipantRegistrationMetricQuery>(`${this.apiUrl}/e-learning/sections/${sectionId}/registration-metrics/participant`)
            .pipe(map((query) => new ParticipantRegistrationMetric(query)));
    }

    getAssessmentSectionMetrics(sectionId: string): Observable<SectionMetric> {
        return this.http.get<ISectionMetricsQuery>(`${this.apiUrl}/assessment-sections/${sectionId}/metrics`).pipe(map((query) => new SectionMetric(query)));
    }

    setSectionsMandatory(sections: Section[], isMandatory: boolean): Observable<Section[]> {
        return this.http
            .patch<ISectionQuery[]>(`${this.apiUrl}/${this.entities}/is-mandatory/bulk`, { ids: sections.map((section) => section.id), is_mandatory: isMandatory })
            .pipe(map((queries) => queries.map((query) => this._newSection(query))));
    }

    async getSectionNotificationPreview(moduleId: string, type: NotificationPreviewRequestType): Promise<INotificationPrevieQuery> {
        return this.http.get<INotificationPrevieQuery>(`${this.apiUrl}/${this.entities}/${moduleId}/preview-notification?type=${type}`).toPromise();
    }
}
