import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ModuleKind } from 'app/library/module/enums/module-kind.enum';
import { ToDoTypeEnum } from 'app/library/to-do/enums/to-do-type.enum';

@Pipe({
    name: 'getToDoAction',
})
@Injectable()
export class GetToDoActionPipe implements PipeTransform {
    ModuleKind = ModuleKind;

    transform(type: ToDoTypeEnum, moduleKind: ModuleKind): string {
        switch (type) {
            case ToDoTypeEnum.CERTIFICATE_TO_DO:
                return 'TODOS.ACTION.RENEW_CERTIFICATE';
            case ToDoTypeEnum.MODULE_TO_DO:
                return moduleKind === ModuleKind.ProgramExecution || moduleKind === ModuleKind.ProgramTemplate ? 'TODOS.ACTION.COMPLETE_PROGRAM' : 'TODOS.ACTION.COMPLETE_COURSE';
            case ToDoTypeEnum.SURVEY_TO_DO:
                return 'TODOS.ACTION.COMPLETE_SURVEY';
            case ToDoTypeEnum.QUIZ_TO_DO:
                return 'TODOS.ACTION.COMPLETE_QUIZ';
            case ToDoTypeEnum.PERSONAL_REVIEW_TO_DO:
                return 'TODOS.ACTION.COMPLETE_PERFORMANCE_REVIEW';
            case ToDoTypeEnum.TEAM_REVIEW_TO_DO:
                return 'TODOS.ACTION.COMPLETE_TEAM_REVIEW';
            default:
                return '';
        }
    }
}
