<button
    mat-stroked-button
    fxLayout="row"
    fxLayoutAlign="center center"
    mat-stroked-button
    class="white-btn py-8 px-12"
    color="white"
    [class.is-active]="isActive"
    [class.is-rounded]="isRounded"
    [disabled]="disabled"
    [matMenuTriggerFor]="matMenu"
>
    <mat-icon [class.mr-8]="label || showThreeDots || showArrowRight || counter" *ngIf="icon">{{ icon }}</mat-icon>
    <mat-icon [class.mr-8]="label || showThreeDots || showArrowRight || counter" *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>

    <span class="text-sm text-nowrap">{{ label | translate }}<span *ngIf="showThreeDots">...</span></span>
    <mat-icon class="ml-8" [svgIcon]="'dropdown_arrow'"></mat-icon>
</button>
