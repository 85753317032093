import { IPerformancePeriodQuery } from '../interfaces/performance-period.query.interface';

// @dynamic
export class PerformancePeriod {
    static label_singular = 'performancePeriod';
    static label_plural = 'performancePeriods';

    static props_to_search = ['name'];

    id: string;
    startDate: string;
    endDate: string;
    overarchingGoalCount: number;

    constructor(query: IPerformancePeriodQuery) {
        this.id = query.id.toString();
        this.startDate = query.start_date;
        this.endDate = query.end_date;
        this.overarchingGoalCount = query.overarching_goal_count ?? null;
    }
}
