import { Injectable } from '@angular/core';
import { MobilePostMessage } from 'app/projects/shared/src/lib/models/mobile-post-message.model';

declare global {
    interface Window {
        webkit: any;
    }
}

interface MobileAppAndroid {
    postMessage(payload: string): any;
}

declare var MobileAppAndroid: MobileAppAndroid;

@Injectable({
    providedIn: 'root',
})
export class MobileAppService {
    constructor() {}

    sendPostMessage(message: MobilePostMessage): any {
        console.log(message, '=== sendPostMessage ===');
        if (typeof MobileAppAndroid !== 'undefined') {
            console.log('=== MobileAppAndroid 1 ===');
            MobileAppAndroid.postMessage(JSON.stringify(message));
        } else if (typeof window?.webkit?.messageHandlers?.MobileAppiOS !== 'undefined') {
            console.log('=== IOS 1 ===');
            window.webkit.messageHandlers.MobileAppiOS.postMessage(JSON.stringify(message));
        }
    }

    processByMobile(message: MobilePostMessage, extension: string): boolean {
        console.log(message, '=== processByMobile ===');
        if (typeof MobileAppAndroid !== 'undefined' && extension === 'pdf') {
            console.log('=== MobileAppAndroid 2 ===');
            MobileAppAndroid.postMessage(JSON.stringify(message));
            return true;
        } else if (typeof window?.webkit?.messageHandlers?.MobileAppiOS !== 'undefined' && extension === 'pdf') {
            console.log('=== IOS 2 ===');
            window.webkit.messageHandlers.MobileAppiOS.postMessage(JSON.stringify(message));
            return true;
        } else {
            return false;
        }
    }
}
