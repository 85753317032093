<app-dialog-header [title]="title" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24" fxLayout="column" fxLayoutAlign="start">
    <div class="content white w-100-p pt-24">
        <mat-tab-group class="border border-gray-200">
            <mat-tab *ngFor="let tab of tabs" [label]="tab.label | translate">
                <mat-table [dataSource]="tab.dataSource.data">
                    <ng-container *ngFor="let column of tab.columns" [matColumnDef]="column.name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header="{{ column.sortable ? '' : null }}">
                            {{ column.header ? (column.header | translate) : '' }}
                        </mat-header-cell>

                        <mat-cell *matCellDef="let message">
                            <ng-container *ngIf="column.cellTemplate === 'avatarCell'">
                                <div class="relative">
                                    <img class="avatar pointer" *ngIf="message.imageUrl; else initials" [alt]="message.title" [src]="message.imageUrl" />
                                    <ng-template #initials>
                                        <div fxLayoutAlign="center center" class="avatar-initials avatar">
                                            {{ message.title | initials }}
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="column.cellTemplate === 'nameCell'">
                                <span class="text-truncate font-semibold w-100-p">{{ message.title }}</span>
                            </ng-container>

                            <ng-container *ngIf="column.cellTemplate === 'defaultCell'">
                                {{ column.nested ? message[column.nested] : message[column.name] }}
                            </ng-container>
                        </mat-cell>
                    </ng-container>
                    <mat-row *matRowDef="let row; columns: tab.columnNames" class="py-8"></mat-row>
                </mat-table>

                <mat-paginator [length]="asyncJobService.messagesPagination.numberOfResults" [pageSize]="10" (page)="onPageChange($event, tab.key)"></mat-paginator>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
