export class TenantDashboardDataset {
    label: string;
    data: number[];
    backgroundColor: string;
    hoverBackgroundColor: string;
    hoverBorderColor: string;

    constructor(name: string, values: number[], color: string, hoverBackgroundColor: string, hoverBorderColor: string) {
        this.label = name;
        this.data = values;
        this.backgroundColor = color;
        this.hoverBackgroundColor = hoverBackgroundColor;
        this.hoverBorderColor = hoverBorderColor;
    }
}
