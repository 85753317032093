import { ITenantDashboardQuery } from '../interfaces/tenant-dashboard.query.interface';
import { TenantDashboardAggregate } from './tenant-dashboard-aggregate';
import { TenantDashboardChartData } from './tenant-dashboard-chart-data';

export class TenantDashboard {
    additionalData: string;
    aggregates: TenantDashboardAggregate[];
    chartData: TenantDashboardChartData;
    chartId: string;
    error: string;
    type: string;
    options: any;
    metricColor: string;

    constructor(query: ITenantDashboardQuery) {
        this.additionalData = query.additional_data;
        if (query.aggregates?.length) {
            this.aggregates = query.aggregates.map((aggregate) => new TenantDashboardAggregate(aggregate.label_key, aggregate.unit_key, aggregate.value));
        } else {
            this.aggregates = null;
        }
        if (query.chart_data) {
            this.chartData = new TenantDashboardChartData(query.chart_data.data_series, query.chart_data.labels);
        } else {
            this.chartData = null;
        }
        this.chartId = query.chart_id;
        this.error = query.error;
        this.type = query.type;
        this.options = query.options;
        this.metricColor = query.metric_color;
    }
}
