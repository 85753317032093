<mat-expansion-panel class="mat-elevation-z0 progress-drawer mr-24" *ngIf="isPanelVisible" hideToggle="true" expanded="isExpanded" (expandedChange)="onExpandedChange($event)">
    <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="flex-start center">
                <mat-icon class="mr-8">menu_open</mat-icon>
                <h4>
                    <span>{{ 'ASYNC_DRAWER.TITLE' | translate }}</span>
                </h4>
            </div>
            <div fxLayout="row" fxLayoutAlign="flex-end center">
                <mat-icon class="pointer mr-8" *ngIf="isExpanded">keyboard_arrow_down</mat-icon>
                <mat-icon (click)="$event.stopPropagation(); onCloseDrawer()">close</mat-icon>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngTemplateOutlet="matExpansionPanelContent"></ng-container>
</mat-expansion-panel>

<ng-template #matExpansionPanelContent>
    <div
        *ngFor="let job of asyncJobs"
        class="jobs-container mb-8 p-16 bg-gray-100"
        role="listitem"
        (click)="onGetJobStatus(job)"
        [ngClass]="job.communicatedStatus | getJobStatusColor: job.stats"
    >
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="mr-8" [ngClass]="job.communicatedStatus | getJobStatusIconColor: job.stats" svgIcon="{{ job.communicatedStatus | getJobStatusIcon: job.stats }}">
            </mat-icon>

            <div fxLayout="column" class="text-truncate">
                <h6 class="my-0 text-sm">
                    {{ 'ASYNC_DRAWER.JOB_TYPE.' + job.type | translate }}
                </h6>
                <div *ngIf="job.type !== 'copy_module'; else moduleName" class="text-truncate text-xs w-100-p file-name" [title]="job.name">
                    <span>{{ job.name | translate }}</span>

                    <span *ngIf="job.additionalInfo">/ {{ job.additionalInfo }}</span>
                </div>
                <ng-template #moduleName
                    ><a class="text-truncate pointer" (click)="$event.stopPropagation(); navigateToModule(job.entityId)">{{ job.name }}</a></ng-template
                >
            </div>
        </div>

        <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="job-stats-container p-16 bg-gray-200 mt-8 pointer"
            (click)="$event.stopPropagation(); openJobDetailsDialog(job)"
        >
            <div class="job-stats" fxLayout="column">
                <div *ngFor="let stat of job.stats | keyvalue; let isLast = last">
                    <p *ngIf="stat.value" class="text-sm mt-0 mb-2" [class.mb-0]="isLast">
                        <span class="font-medium">{{ stat.value }}</span> &nbsp;
                        {{ 'ASYNC_DRAWER.RESPONSE_STATS.' + stat.key | translate }}
                    </p>
                </div>
            </div>
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>
</ng-template>
